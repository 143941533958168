 // 2019 Annual Report Customizations

@oag-report-color: #60766F; //Pantone 5545 U
@oag-report-th-bg-color: #cadad2;
@oag-report-td-bg-color: #e3ede8;
@oag-font-oversized: floor((@plone-font-size-base * 3.7));

.section-2019.subsection-annual-report {

    // Tables
    thead {
        background-color: @oag-report-th-bg-color !important;
    }
    .listing, .plain {
        > tbody > tr:nth-child(odd) {
            > td,
            > th {
                background-color: @oag-report-td-bg-color;
            }
        }
        > tbody > tr:nth-child(even) {
            > td,
            > th {
                background-color: @oag-report-td-bg-color;
            }
        }
    }

    table > thead > tr > th, table > tbody > tr > th, table > tfoot > tr > th, table > thead > tr > td, table > tbody > tr > td, table > tfoot > tr > td {
        border: 2px solid white;
    }

// Colours

    h1, h2, h3, h4, h5, h6 {
        color: @oag-report-color;
    }

    .callout, p.callout {
        border-left-color: @oag-report-color;
    }

    .relatedItems {
        border-left-color: @oag-report-color;
    }

    .portletFeedbackPortlet {

        .portletHeader {
            color: @oag-report-color;
        }
        .portletContent {
            background-color: @oag-report-color;
        }
    }

    article.entry a {
        color: @oag-report-color;
    }

    .plone-navbar {
        background-color: @oag-report-color;
    }

    // Links
    // Doesn't pass AAA contrast

    a, #portal-footer-wrapper a {
        color: @oag-report-color;
    }
    .plone-breadcrumb ol, .plone-breadcrumb ol a {
        color: @plone-link-color;
    }

    #homepage-facebook a, #homepage-oagrss a, #homepage-oagsubs a, #homepage-social a, #document-actions-facebook a, #document-actions-oagrss a, #document-actions-oagsubs a, #document-actions-sharing a {
        color: @oag-report-color;
    }

    .plone-nav > li > a {
        color: white;
    }

    // Site header
    #portal-header {
        background: url(/++theme++oag2018/static/img/audit-everyone-tiled.png) no-repeat 420px 0;
    }

}

// Landing page

.section-2019.subsection-annual-report-index {

    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(202,218,210,1) 100%);

    #mainnavigation-wrapper, #above-content-wrapper, #portal-searchbox {
        display:none;
    }

    #portal-footer-wrapper {
        background: transparent;
    }

    #ar-container {
        margin: auto 5%;
    }

    .ar-numbers1 {
    }

    .ar-title, .ar-reports, .ar-numbers1, .ar-numbers2, .ar-toc {
        border-top: 2px dashed @oag-black;
        padding-top: 18px;
        padding-bottom: 18px;
    }

    .ar-toc {
        a {
            font-size: @plone-font-size-h1;
            font-weight: @plone-font-weight-bold;
            line-height: 1.25;
        }
    }

    .ar-numbers1l {
        border-right: 2px dashed @oag-black;
    }

    h1 {
        text-align: center;
    }

    h2 {
        font-size: @oag-font-oversized;
        margin-top: 0;
        margin-bottom: 0;
    }

    h3 {
        font-size: @plone-font-size-h2;
        color: @oag-black;
        margin-top: 11px;
    }

    .number-highlight {
        font-size: @plone-font-size-h1;
        font-weight: @plone-font-weight-bold;
        line-height: 1.25;
    }

    .ar-reports {
        p {
        white-space: pre-line;
        padding-top: 2em;
        }
        img {
            padding-right: 2em;
        }
    }

    .ar-numbers1 {
        p {
            white-space: pre-line;
            text-align: center;
            margin-left: 30%;
            margin-right: 15%;
        }
    }

    .ar-numbers1r {
        p {
            margin-left: 285px;
            padding-top: 2em;
        }
    }

    .ar-numbers2 {
        img {
            float: none !important;
            clear: none !important;
            display: block;
            margin: auto;
        }
        p {
            text-align: center;
            white-space: pre-line;
        }
    }

    #ar-container img {
        float: left;
        clear: left;
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

@media (max-width: @plone-screen-sm-max) {
    .section-2019.subsection-annual-report-index {
        .ar-numbers1l {
            border-right: none;
        }

        .ar-numbers1r {
            border-top: 2px dashed @oag-black;
        }

    }
}

@media (max-width: @plone-screen-xs-min) {
    .section-2019.subsection-annual-report-index {
        .ar-numbers1r p {
            margin-right: 0;
        }
    }
}

@media (max-width: 320px) {
    .section-2019.subsection-annual-report-index {
        h2 {
            font-size: @plone-font-size-h1;
        }
        .ar-numbers1r p {
            margin-left: 0;
        }
    }
}
