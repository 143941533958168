@media (min-width: 1255px) {
    body.portaltype-home-with-portlets {
        #hero-column-social {
            position: relative;
            float: left;
            clear: left;
            width: 100%;
            max-width: 596px;
            padding-top: 0;
            padding-bottom: @hero-border-width;
            margin-left: 0;
            ul#oag-social {
                padding-left: 4%;
                li {
                    padding-bottom: 0;
                    float: left;
                    padding-right:13%;
                    a span.fa-2x {
                        font-size: 2em;
                    }
                }
            }
        }
        article#content {
            max-width: none;
        }
        #hero-row-middle {
            float: left;
        }

    }
}
// use screen here so it overrides the previous min-width media rule
@media screen and (min-width: 1520px) {
    body.portaltype-home-with-portlets {
        #hero-row-bottom {
            position: absolute;
            margin-top: 252px;
            margin-left: 608px;
        }
        #hero-column-social {
            position: absolute;
            margin-top: 462px;
        }
    }
}

@media (max-width: 920px) {
    body.portaltype-home-with-portlets {
                #hero-column-social {
            position: relative;
            float: left;
            width: 100%;
            padding-top: 0;
            padding-bottom: @hero-border-width;
            margin-left: 0;
            ul#oag-social li {
                padding-bottom: 0;
                float: left;
                padding-right:6%;
                a span.fa-2x {
                    font-size: 2em;
                }
            }
        }

    }
    .portletFeedbackPortlet .portletContent p.leaveFeedback {
        padding: 1em 0;
    }
}

@media (max-width: @plone-screen-md-min) {
    body.portaltype-home-with-portlets {
        .portlet-hero-panel {
            margin-bottom: @hero-border-width;
        }
    }
}

@media (max-width: @plone-screen-xs-min) {
    body.portaltype-home-with-portlets {
        #heroimage, #schoolsimage, #dataimage {
            img {
                display: none;
            }
        }
        .portlet-hero-panel, .portlet-school-resources, .portlet-data, .portlet-lower-left, .portlet-lower-right {
            width: 100%;
            max-width: 100%;
        }
    }

}

@media (max-width: @plone-screen-md-min) {
    body.portaltype-home {
        #heroimage {
            width: 100%;
            max-width: 596px;
            border-right:0;
            border-bottom: (@plone-padding-large-vertical - 1) solid transparent;
        }
        #hero-column-left, #hero-column-right {
            width: 50%;
            max-width: 298px;
            border-bottom: (@plone-padding-large-vertical - 1) solid transparent;
        }
        #hero-column-social {
            width: 100%;
            ul#oag-social li {
                padding-bottom: 0;
                float: left;
                padding-right: 7%;
                a span.fa-2x {
                    font-size: 2em;
                }
            }
        }
    }
        #portal-searchbox {
        margin-top: 64px;
    }

}

@media (max-width: @plone-screen-xs-min) {
    body.portaltype-home {
        #heroimage {
            border-right: @hero-border-width solid transparent;
            max-width: none;
        }
        #heroimage img {
            display: none;
        }
        #herocaption {
            font-size: 100%;
            position: relative;
        }
        #hero-column-left, #hero-column-right {
            width: 100%;
            max-width: none;
        }
        .hero-tl, .hero-tr, .hero-bl,  .hero-br {
            img {
                display: none;
            }
            #blogcaption, #schoolscaption, #datacaption, #headlinecaption {
                position: relative;
                font-size: 100%;
            }
        }
        #hero-column-social {
            padding-top: @hero-border-width;
        }
    }
    .rrssb-buttons {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: @plone-screen-xs-max) {
    aside#accordion.ui-accordion {
        header.portletHeader a:after {
            content: "⟩";
            position: absolute;
            font-size: 20px;
            margin-top: -2px;
            margin-left: 5px;
            margin-right: 20px;
        }
    }
}

@media (max-width: 320px) {
    input#searchGadget {
        width: 75%;
    }
}
