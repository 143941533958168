/* our custom styles */

/* TinyMCE styles */

.tiny-left {
    text-align:left;
}

.tiny-center {
    text-align:center;
}
.tiny-right {
    text-align: right;
}
.tiny-full {
    text-align: justify;
}

/* TinyMCE templates */

// image-grid-2x2:
.image-grid-2x2 {
    padding: 0;
    margin: 0 0 @plone-grid-gutter-width 0;
    list-style: none;
    .make-row();

    > li {
        .make-xs-column(12);
        .make-sm-column(6);
        margin-top: @plone-grid-gutter-width;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

/* wall of images */
#wallofimages {
  line-height: 0;

  -webkit-column-count: 5;
  -webkit-column-gap:   0px;
  -moz-column-count:    5;
  -moz-column-gap:      0px;
  column-count:         4;
  column-gap:           0px;
}

#wallofimages img {

  width: 100% !important;
  height: auto !important;
  display: block !important;
}

/* reports portal */

.portlet {
    font-size: 87.5%;
    p {
        color:  @plone-gray;
    }
    img {
        max-width: 100%;
        height: auto;
        display:block;
        margin-left: auto;
        margin-right: auto;
    }
}

.portletReportsPortlet {
    h1 {
        font-size: 100%;
        font-weight: bold;
        img {
            display: block;
        }
    }
    img {
        padding-bottom: 0.5em;
    }
}

.reportsPdf, .reportsEpub, .reportsFeedback {
    padding-top: 0 !important;
    padding-bottom: 2%;
/*    a::before {
        font-family:"Fontello";
        font-size: 100%;
        padding: 0;
        margin:0;
        position: relative;
        left: inherit;
        display: inline-block;
        color: inherit;
        width: 20px;
        height: 20px;
        text-align: center;
        margin-right: @plone-padding-base-vertical;
        content: '\e811';
    }*/
}

/* .reportsEpub a::before {
    content: '\e80e';
}
.reportsFeedback a::before {
    content: '\e831';
} */

/* images */

img {
    max-width: 100%;
}

/* layout */

#portal-logo {
    margin-top: 15px;
    margin-bottom: 10px;
}
main#main-container {
    margin-top: calc(@plone-grid-gutter-width / 2);
}
article#content {
    max-width: 901px;
}

/* basic styles */

// .container {
//     margin-left: 0;
//     margin-right: 0;
// }

.dont-wrap {
    white-space: nowrap;
}

.description {
    font-size: @plone-font-size-h4;
    font-weight: @plone-font-weight-regular;
    color: @plone-gray;
    font-style: italic;
}

.discreet {
    font-size: @plone-font-size-small;
}

.pullquote {
    font-family: @plone-font-family-serif;
//    font-size: @plone-font-size-small;
    font-style: italic;
//    background:  url(/++theme++oag2018/static/img/pull-quote.gif) no-repeat top left;
    padding: 0.64em 1em 1em 0;
    font-size: @plone-font-size-large;
    &:before {
        .fa-icon;
        .fas;
        content: @fa-var-quote-left;
        padding-right: 0.5em;
        font-size: @plone-font-size-h1;
        color: @oag-bark;
        vertical-align: -16%; // eyeballed
    }
}

.callout, p.callout {
    border-left: 1em solid @oag-bark;
    a {
        color: @plone-link-color-on-grey;
    }
}

.updated {
    font-size: @plone-font-size-h6;
}

.cite, cite {
    font-style: italic;
}

pre {
    white-space: pre-wrap;
    word-break: normal;
}

.relatedItems {
    background: @plone-portlet-header-bg;
    padding: 1em;
    border-left: 1em solid @oag-bark;
    clear: both;
    header {
        font-weight: @plone-font-weight-bold;
        padding-bottom: 0.25em;
    }
    ul li {
        font-size: @plone-font-size-small;
        a {
            color: @plone-link-color-on-grey;
        }
    }
}

.pagination li {
    line-height: 2.2;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25% !important; /* 16:9 */
	padding-top: 25px !important;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


.photoAlbumEntryTitle {
    display: block;
}

// dl styling
#content-core {

    dl:after {content:"";display:table;clear:both;}
    dd {
        padding:.5em 0;
    }
    dl {
        width:100%;
    }
    dt, dd {
        display:inline-block;
        width:30%;
    }
    dt {
        text-align:right;
        font-weight:bold;
        clear:left;
        float:left;
    }
    dd {
        width:70%;
        padding-left:1em;
        clear:right;
    }
    dd + dd {
        float:right;
        clear:both;
    }
    dt:first-child {
        padding-top:.5em;
    }
    dd + dt {
        clear:both;
        padding-top:.5em;
    }
    dt + dt {
        width: 100%;
        float: none;
        padding: 0 70% 0 0;
    }
    dt + dt + dd {
        margin-top: -2em;
    }
    dt + dt + dd + dt {
        margin-top: 2em;
    }
}
// Do not apply above for Search results page, Filter these results popout
#searchform .actionMenu dd {
    display: none;
    padding: 15px;
    width: 300px;
}
#searchform .actionMenu.activated dd {
    display: block;
}


// margin for nested ordered list

#content-core ol ol {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

/* typography */

h1, h2, h3, h4, h5, h6 {
  margin-top: @plone-line-height-computed;
}

.plone-breadcrumb {
    font-size: @hero-font-size;
    ol > li {
        line-height: inherit;
        &+ li::before {
            top: -6px;
        }
        &+ li::after {
            top: -6px;
        }
    }
}

.plone-nav >li >a {
    font-size: @plone-font-size-base;
}

.portletNavigationTree .portletContent > ul {
    ul.navTreeLevel2 {
        padding-left: 0.5em;
    }
    ul ul.navTreeLevel3 {
        padding-left: 0.5em;
    }
}

/* tables */

table, th {
    color: @oag-black;
}

th[scope="row"] {
    background-color: #d7e1dc !important;
    border: 1px @plone-btn-standalone-border solid !important;
}

/* invert zebra striping */

.listing, .plain {
    > tbody > tr:nth-child(odd) {
        > td,
        > th {
            background-color: @plone-body-bg;
        }
    }
    > tbody > tr:nth-child(even) {
        > td,
        > th {
            background-color: @plone-table-bg-accent;
            a {
                color: @plone-link-color-on-grey;
            }
        }
    }
}

// No zebra striping for .vertical

.vertical {
    > tbody {
        > tr:nth-child(odd),
        > tr:nth-child(even) {
            > td,
            > th {
                background-color: @plone-body-bg;
            }
        }
    }
}

thead {
    background-color: #d7e1dc;
}

/* fancy grid (grid) */
table.grid > tbody > tr > td {
    text-align: right;
}

/* subdued grid (plain) and (invisible) */
table.plain > thead {
    background-color: @plone-body-bg;
}

table.plain, table.invisible {
    > thead, > tbody, > tfoot {
        > tr {
            > th, > td {
                padding: @plone-table-cell-padding;
                line-height: @plone-line-height-base;
                vertical-align: top;
                border-top: 0 solid @plone-table-border-color;
                border-collapse: collapse;
                border-width: 0 0 1px 0;
            }
        }
    }
}

table.invisible {
    border: 0;
    > thead {
        background-color: @plone-body-bg;
    }
    th, td {
        border: 0;
    }
}

/* Hero Panel */


@hero-border-width: 12px;
@hero-font-size: 80%;
@hero-font-size-h1: 0.8 * @plone-font-size-large;
@hero-headline-bg: @plone-breadcrumb-bg;
@hero-social-width: 3.5%;
@hero-column-width: (100% - @hero-social-width) / 4;

body.portaltype-home-with-portlets {
#document-actions-social {
    display:none;
}

    .portlet {
        h1,h2,h3,h4,h5,h6 {
            font-size: @plone-font-size-large;
            font-weight: @plone-font-weight-medium;
        }
    }
    // .container {
    //     margin-left: 0;
    //     margin-right: 0;
    // }

    .portlet-hero-panel {
        max-width: 596px;
        width: @hero-column-width * 4;
        float: left;
        margin-right: @hero-border-width;
        margin-bottom: @hero-border-width;
    }

    .portlet-school-resources, .portlet-lower-left  {
        margin-right: @hero-border-width;
        clear: left;
    }
    .portlet-school-resources, .portlet-data, .portlet-lower-left, .portlet-lower-right {
        max-width: 292px;
        width: @hero-column-width * 2;
        float: left;
    }

    .portlet-hero-panel, .portlet-school-resources, .portlet-data, .portlet-lower-left, .portlet-lower-right {
        img {
            float:none;
            max-width: 100%;
            height: auto;
            display: block;
            margin:0;
            padding:0;
        }
        header p {
            margin:0;
            padding:0;
        }
    }

    #dataimage, #schoolsimage, #heroimage {
        a {
            border-bottom: 0;
            padding: 0;
        }
    }

    #hero-column-social {
        // float: left;
        //    position: relative;
        // magic number related to width of hero image column
        position: absolute;
        margin-left: 608px;
        width: @hero-social-width;
        margin-right: @hero-border-width;
        ul#oag-social {
            list-style-type: none;
            padding-left: 0;
            li {
                padding-bottom: 95%;
                a span.fa-2x {
                    font-size: 1.75em;
                }
            }
        }
    }

    #hero-row-bottom {
//        float: left;

    }
}

/* Vertical version */
/*    .container {
        margin-left: 0;
        margin-right: 0;
    }
    .portlet-hero-panel, #hero-column-right,    .portlet-lower-left, .portlet-lower-right {
        float: left;
        margin-right: @hero-border-width;
        width: 48%;
        img {
                float: none;
        max-width: 100%;
        height: auto;
        display: block;
        margin:0;
        padding:0;
        }
        header p {
            margin: 0;
            padding: 0;
        }
        a {
            font-size: @plone-font-size-large;
        }
    }

    #hero-column-bottom {
        float: left;
        clear:right;
    }

#hero-column-social {
    float: left;
    clear: left;
    //    position: relative;
    // magic number related to width of hero image column
    width: 100%;
    ul#oag-social {
        list-style-type: none;
        padding: 1.25% 10%;
        li {
            padding-right: 100% / 8;
            display: inline-block;
            a span.fa-2x {
                font-size: 1.75em;
            }
        }
    }
}
*/

#homepage-facebook, #homepage-oagrss, #homepage-oagsubs, #homepage-social, #document-actions-facebook, #document-actions-oagrss, #document-actions-oagsubs, #document-actions-sharing {
/*    .glyphicon {
        display: none;
    }*/
    a {
        color: @plone-sitenav-link-hover-bg;
        border-bottom-width: 0;
    }
}


/* Home page document actions (social media icons) */


body.portaltype-home {
#document-actions-social {
    display:none;
}

    article#content {
        max-width: none;
    }

    .hero-panel {
        max-width: 1200px;
        margin: 0 auto 0 0;
        /* disable icon for external http links */
/*   .glyphicon.link-external {
       display: none;
   } */
   /* disable icon for external https links */
/*   .glyphicon.link-https {
       display: none;
   }*/
    }


    #heroimage {
        float: left;
        // magic number to help hero image / other column height alignment
        width: @hero-column-width * 2.03125;
        border-right: @hero-border-width solid transparent;
        position:relative;
        img {
            float: none;
            max-width: 100%;
            height: auto;
            display: block;
        }
    }

    #hero-column-left, #hero-column-right {
        float: left;
        width: @hero-column-width;
        border-right: @hero-border-width solid transparent;
    }

    .hero-tl, .hero-tr, .hero-bl, .hero-br {
        overflow: hidden;
        img {
            float: none;
            height: auto;
	        //max-height: 184px;
	        //max-width: 298px;
            max-width: 100%;
            display: block;
            //border: 1px solid red;
        }
        position: relative;
        max-height: 184px;
    }

    .hero-tl, .hero-tr {
        border-bottom: @hero-border-width solid white;
    }

    .hero-tl, .hero-tr, .hero-bl, .hero-br {
        #blogcaption, #schoolscaption, #datacaption {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            line-height: 1em;
            font-weight: bold;
            color: @oag-black;
            font-size: @hero-font-size;
            background-color: @hero-headline-bg;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e6e4e4+0,e6e4e4+90,ffffff+100 */
            background: rgb(230,228,228); /* Old browsers */
            background: -moz-linear-gradient(left, rgba(230,228,228,1) 0%, rgba(230,228,228,1) 90%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(230,228,228,1) 0%,rgba(230,228,228,1) 90%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(230,228,228,1) 0%,rgba(230,228,228,1) 90%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6e4e4', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
            padding: 0.8em;

            //min-height: 70px;
            h1 {
	            color: @oag-bark;
                font-size: @hero-font-size-h1;
                padding: 0 0 0.4em 0;
                margin: 0;
            }
            p span {
                color: @oag-bark;
            }
        }
    }

    #herocaption {
        bottom: 0;
        left: 0;
        width: 100%;
        position: absolute;
        line-height: 1.2em;
        font-weight: bold;
        color: @oag-black;
        font-size: @hero-font-size;
        background-color: @hero-headline-bg;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e6e4e4+0,e6e4e4+90,ffffff+100 */
        background: rgb(230,228,228); /* Old browsers */
        background: -moz-linear-gradient(left, rgba(230,228,228,1) 0%, rgba(230,228,228,1) 90%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(230,228,228,1) 0%,rgba(230,228,228,1) 90%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(230,228,228,1) 0%,rgba(230,228,228,1) 90%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6e4e4', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
        padding: 0.8em;
        h1 {
            color: @oag-bark;
            font-size: @hero-font-size-h1;
	        padding: 0 0 0.4em 0;
            margin: 0;
        }
    }

    #headlinecaption {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        line-height: 1.2em;
        font-weight: bold;
        color: @oag-black;
        font-size: @hero-font-size;
        background-color: @hero-headline-bg;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e6e4e4+0,e6e4e4+90,ffffff+100 */
        background: rgb(230,228,228); /* Old browsers */
        background: -moz-linear-gradient(left, rgba(230,228,228,1) 0%, rgba(230,228,228,1) 90%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(230,228,228,1) 0%,rgba(230,228,228,1) 90%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(230,228,228,1) 0%,rgba(230,228,228,1) 90%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6e4e4', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
        padding: 0.8em;
        max-height: 184px;
        //    height: 184px;
        overflow: hidden;
        h1 {
            color: @oag-bark;
            font-size: @hero-font-size-h1;
            padding: 0 0 0.4em 0;
            margin: 0;
        }
    }

    /* Home page social media links */

    #hero-column-social {
        float: left;
        //    position: relative;
        // magic number related to width of hero image column
        width: @hero-social-width * 0.78;
        ul#oag-social {
            list-style-type: none;
            padding-left: 0;
            li {
                padding-bottom: 95%;
                a span.fa-2x {
                    font-size: 1.75em;
                }
            }
        }
    }


    #homepage-facebook, #homepage-oagrss, #homepage-oagsubs, #homepage-social, #document-actions-facebook, #document-actions-oagrss, #document-actions-oagsubs, #document-actions-sharing {
        /*    .glyphicon {
        display: none;
        }*/
        a {
            color: @plone-sitenav-link-hover-bg;
            border-bottom-width: 0;
        }
    }


    /* Home page document actions (social media icons) */

    #document-actions-social {
        display:none;
    }

    #viewlet-below-content-body .rrssb-buttons {
        display: none;
    }
}

/* OAG social media links / document actions */
#portal-footer .row + .site-actions ul #oag-documentActions ul.oag-social {
    margin-top: 0;
}
#oag-documentActions {
    display: inline-block;
   ul.oag-social {
	     margin-top: 0;
        background-color: white;
        padding: 0 0 0 2%;
        li {
            list-style-type: none;
            vertical-align: middle;
            a {
                font-size: @plone-font-size-h4;
            }
        }
    }
}

/* Page specific rss feed */

// ul.oag-documentActions {
//     padding-left: 0;
//     li {
//         float: left;
//         margin-top: 10px;
//         margin-right: 5px;
//         list-style-type: none;
//         a {
//             color: #f26522;
//         }
//     }
// }

/* rrssb styles */

.rrssb-buttons {
    text-align: center;
    width: 20%;
    margin: 0 40%;
    display: none;

    li {
       max-width: 260px;
    }
}

#portal-footer-wrapper {
  .rrssb-buttons {
      margin: 0 0 0 0 !important;
      width: 80%;
      text-align: left;
  }
}


/* Footer styles */

#portal-footer .row + .site-actions {
    border-top: 0;
    text-align: left;
    ul {

        text-transform: none;
        font-size: @hero-font-size;
        padding-left: 0;
        margin-left: calc(@plone-grid-gutter-width / 2);
        margin-top: 1em;
        li {
            padding: 0;
            margin-left: 0;
            margin-right: @plone-grid-gutter-width;
            a {
                font-weight: normal;
                color: @plone-link-color;
            }
        }
        li:last-child {
            margin-right: 0;
        }
        li#siteaction-address, li#siteaction-copyright {
            margin-left: 0;
            margin-top: 10px;
            color: @oag-black;
            font-weight: @plone-font-weight-regular;
            display: block;
        }
    }
}

/* feedback portlet */

.portletFeedbackPortlet {
    border: 0;
    .portletHeader {
        background-color: white;
        color: @oag-bark;
        font-weight: bold;
        text-align: center;
    }
    .portletContent {
        background-color: @oag-bark;
        p {
            color: white;
            img {
                margin: 0 auto;
                width: 100%;
            }
        }
        p.feedbackImage {
            padding: 0;
        }
        p.leaveFeedback {
            text-align: center;
            background-color: white;
            padding: 1em;
            line-height: 2.2;
            white-space: nowrap;
            a {
                background-color: @plone-link-color;
                padding: 0.5em;
                color: white;
                text-decoration: none;
                font-weight: bold;
                text-shadow: 0 1px rgba(0,0,0,.25);
                border: 1px solid #00587e;
            }
        }
    }
}

/* Collection listing view */

.oag-category {
    margin-bottom: @plone-padding-base-vertical;
    margin-left: (75 + @plone-padding-base-horizontal);
    padding-top: 0 !important;
    ul {
        list-style: none;
        padding: 0;
        display: inline;
    }
    li {
        display: inline-block;
        margin-bottom: 5px;
        a {
            display: inline !important;
            padding: .2em .6em .3em !important;
        }
    }
}

.oag-listing-thumb {
    vertical-align: text-top;
    color: @plone-link-color;
    float:left;
    padding-right: @plone-padding-base-horizontal;
}

.oag-blog-description {
    margin-left: (116 + @plone-padding-base-horizontal) !important;
}

.portraitPhoto {
    max-width: 75px;
}

div.oag-blog-byline {
    font-size: @plone-font-size-small;
    span a {
        display: inline-block;
    }
}
article.entry a {
    color: @oag-bark;
    font-weight: bold;
    padding: 0 0 (@plone-line-height-computed / 2.2) 0;
    border-bottom: 0 solid transparent !important;
    display: block;
}

article.entry {
    margin-bottom: @plone-padding-base-horizontal;
    p.discreet {
        margin-left: 48px;
    }
}

.entries::before {
    clear: left;
}

.portletCollection > ul > li > a {
    padding-left: 0;
}
/* Search section viewlet */

#section-searchbox {
/* copied and adapted from #portal-searchbox in header.plone.less. */
    clear: left;
    margin-left: 3px;
    margin-top: 60px;
    padding-top: 20px;
    position: relative;
    white-space: nowrap;
    z-index: 2;
    display: inline;
    label {font-size: 86.667%}
    [type="submit"] {
        display: inline-block;
        margin-left: -1px;
        vertical-align: top;
        width: auto;
        display: inline-block;
    }
    input#sectionSearchGadget {
        max-width: 80%;
        display: inline-block;
    }
}

/* Search results page */

#oagSearchUtilLinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

#oagSearchReturnLink {
    margin-bottom: 1em;
}

.breadcrumb-category {
  display: inline;
  padding: .2em .6em .3em;
  font-size: @plone-font-size-small;
  font-weight: @plone-font-weight-regular;
  line-height: 1;
  color: @plone-link-color;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: @plone-border-radius-base;
  background-color: @plone-btn-standalone-bg;

  &[href] {
    &:hover,
    &:focus {
      background-color: darken(@plone-btn-standalone-bg, 10%);
    }
  }

  a& {
    &:hover,
    &:focus {
      color: @plone-link-color;
      text-decoration: none;
      cursor: pointer;
    }
  }

  // Empty
  &:empty {
    display: none;
  }
}

/* Navigation portlet accordion overriders */

aside#accordion.ui-accordion {
    &.ui-widget {
        font-family: @plone-font-family-sans-serif;
    }
    &.ui-helper-reset {
        line-height: inherit;
        text-decoration: inherit;
    }
    .ui-accordion-header {
        margin-top: 0;
        border: 0;
    }
    .ui-helper-reset {
        line-height: inherit;
        text-decoration: inherit;
    }
    .ui-accordion-header-icon {
        display: none;
    }
    .ui-accordion-content {
        padding: 0;
    }
    .ui-widget-content {
        border: 0;
    }
}

/* Edit tool bar bugfix */

.plone-toolbar-left #edit-zone {
    z-index: 11;
}

@media (min-width: @plone-screen-xs-max) {
    .plone-toolbar-left #edit-zone {
        z-index: 11;
    }
}

/* Form fixes */
.ArchetypesStringWidget, .ArchetypesTextAreaWidget {
    width: 100%;
}

.plone-modal-body form {
    margin-left: 0;
}

/* Remove excess required marker from radio buttons */
label .required.radio-widget::after {
    content: '';
}

/* Basic flexbox setup */

.oagfb {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.oagfb > div {
    max-width: 200px;
    text-align: center;
    margin-bottom: 1em;
}

/* 2021 Housing report */

#housingroles {
        position: relative;
        padding-bottom: 118.8889%;
        padding-top: 1px;
        height: 0;
        overflow: hidden;
        clear: both;
        iframe {
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: 0px none transparent;
            padding: 0px;
}
}

#mandatetable {
    position: relative;
    padding-bottom: 505px;
    height: 0;
    overflow: hidden;
    clear: both;
    iframe {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: 0px none transparent;
        padding: 0px;
    }
}

#ar93 { // aspect ratio 0.9278 x:2010 y:1865
    position: relative;
    padding-top: 1px;
    padding-bottom: 100%; // aspect ratio + 2% gets rid of iframe scroll bar
    vertical-align: middle;
    overflow: hidden;
    height: 0;
    clear: both;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: 0 none transparent;
        padding: 0;
    }
}

.big {
    font-family: @plone-font-family-serif;
    font-size: 41px !important;
    font-style: italic;
    color: @oag-black;
    margin: 0.5em 0 1em 0;
}

.documentFirstHeading {
    color: @oag-bark;
}

h5 {
    font-style: italic;
}

/* Event Registration version */

article.event.summary {
    float: none;
    max-width: none;
    box-shadow: none;
    padding: inherit;
    margin-left: inherit;

    h2 {
        color: @oag-teal-dark;
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 0.75em;
        margin-top: 0.75em;
    }
    h2::after {
        position: absolute;
        content: "";
        height: 6px;
        width: 9%;
        left: 0;
        bottom: 0;
        background-color: @oag-teal;
    }

}

/* Campaign Manager email form Iframe */
#emailsubs {
    height: 28em;
    iframe {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: 0 none transparent;
        padding: 0;
    }
}

/* Fix for old styles */

table.invisible {
    visibility: visible !important;
}

/* Improve toc display positioning */

.pat-autotoc:not(.autotabs) .autotoc-nav {
    max-width: 50%;
    a {
        color: @plone-link-color-on-grey;
    }
}

@media (max-width: 767px) {
    .pat-autotoc:not(.autotabs) .autotoc-nav {
        float: none;
        margin: 0 0 20px;
        max-width: none;
    }
}
.autotoc-header {
    margin-top: 0;
    font-size: @plone-font-size-h4;
}

/* 2024 July experiments */

#oag-documentActions {
//    display: none;
}

#content {
//    margin-left: 20%;
}

.hp3verticals {
//    align-items: flex-start !important;
//    padding-left: @plone-grid-gutter-width;
}



.documentFirstHeading {
//    padding-top: 0.75em;
}
